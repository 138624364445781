@import "../../styles/variables.scss";

.Footer {
  // flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  background-color: $darkgray;
  color: $lightgray;

  .label {
    @extend .hand__typing;
    font-size: 1.3rem;
  }

  a {
    color: $lightgray;
    &:hover {
      color: $yellow;
    }
    &:visited {
      color: $lightgray;
    }
    &:active {
      color: $yellow;
    }
  }

  .icons {
    margin: 15px;
    font-size: 35px;
    cursor: pointer;
    &:hover {
      color: $yellow;
    }
  }
}

.wide {
  width: calc(100vw + 15px);
}

.shape {
  @extend .wide;
  transform: rotate(-1.75deg);
  position: relative;
  left: -10px;
  top: 4rem;
  background-color: $darkgray;
  padding: 4rem;
  z-index: -1;
}
