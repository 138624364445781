@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-comic_hand__typing__18CNm{font-family:'Architects Daughter', cursive}.loading-comic_content__heading__3diG4{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.loading-comic_LoadingComic__1jDVk{display:flex;flex-direction:column;text-align:left}.loading-comic_LoadingComic__1jDVk .loading-comic_image__container__3_7sk{margin:20px}.loading-comic_LoadingComic__1jDVk .loading-comic_footer__1zUkl{display:flex;justify-content:space-between;align-items:center}

.footer_hand__typing__1A9JV,.footer_Footer__11REs .footer_label__EwDQC{font-family:'Architects Daughter', cursive}.footer_content__heading__1TwyH{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.footer_Footer__11REs{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:25px;background-color:#121212;color:#e3e3e3}.footer_Footer__11REs .footer_label__EwDQC{font-size:1.3rem}.footer_Footer__11REs a{color:#e3e3e3}.footer_Footer__11REs a:hover{color:#fecc33}.footer_Footer__11REs a:visited{color:#e3e3e3}.footer_Footer__11REs a:active{color:#fecc33}.footer_Footer__11REs .footer_icons__2N-N4{margin:15px;font-size:35px;cursor:pointer}.footer_Footer__11REs .footer_icons__2N-N4:hover{color:#fecc33}.footer_wide__2C1Ic,.footer_shape__3Fygj{width:calc(100vw + 15px)}.footer_shape__3Fygj{transform:rotate(-1.75deg);position:relative;left:-10px;top:4rem;background-color:#121212;padding:4rem;z-index:-1}

.header_wide__1jRu7,.header_shape__1etfg{width:calc(100vw + 10px)}.header_Header__1afXt{margin:auto;background-image:url(/static/media/background.78566201.jpg);background-repeat:no-repeat;background-size:cover;color:#fff}.header_Header__1afXt a{font-size:15px;letter-spacing:1.5px;text-decoration:underline}.header_Header__1afXt .header_logo__PtlWl{margin:0.75rem;height:5em}.header_Header__1afXt .header_content__93KnI{position:relative;display:flex;justify-content:center;align-items:center}.header_Header__1afXt .header_overlay__1tGKS{text-align:center;background-color:rgba(0,0,0,0.7);padding:1rem;z-index:2}.header_shape__1etfg{transform:rotate(-1.75deg);position:relative;left:-10px;top:-3rem;background-color:#fecc33;padding:4rem;z-index:-1}

.about_hand__typing__110Se{font-family:'Architects Daughter', cursive}.about_content__heading__14Pyr{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.about_About__2c3Pg{max-width:800px}.about_About__2c3Pg h1{color:#121212}.about_About__2c3Pg .about_desc__1R2u_{text-align:left;margin-top:2rem}.about_About__2c3Pg .about_headshot__PFRn_{margin:auto;width:250px;height:250px;background-size:275px;background-image:url(/static/media/JasonLeeHodges.84f11aa3.jpg);background-position:60% 25%;background-repeat:no-repeat;border-radius:1.2rem}

.book_hand__typing__1tpFk{font-family:'Architects Daughter', cursive}.book_content__heading__5fDqH{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.book_Book__3pqgE{max-width:800px}.book_Book__3pqgE h1{color:#121212}.book_Book__3pqgE button{margin:2rem}.book_Book__3pqgE .book_book__cover__2NRs3{height:350px;margin:2rem}.book_Book__3pqgE .book_desc__38A4p{text-align:left}

.most-recent-comic_hand__typing__1jFdx{font-family:'Architects Daughter', cursive}.most-recent-comic_content__heading__2zrKe{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.most-recent-comic_MostRecentComic__3tMaJ{display:flex;flex-direction:column}

.body_hand__typing__3W-PM,.body_Body__2yAS5 .body_Navigation__27z3A button{font-family:'Architects Daughter', cursive}.body_content__heading__1bXpW{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.body_Button__yQxhw,.body_Body__2yAS5 .body_Navigation__27z3A button{border:none;padding:0 30px;background:none;font-size:30px;cursor:pointer}.body_Button__yQxhw:hover,.body_Body__2yAS5 .body_Navigation__27z3A button:hover{opacity:0.6}.body_Body__2yAS5{flex-grow:1;justify-content:center;align-items:center;position:relative;top:-110px}@media (max-width: 500px){.body_Body__2yAS5 .body_Navigation__27z3A{display:flex;flex-direction:column;position:relative;top:0px}}.body_Body__2yAS5 .body_content__38MBV{display:flex;justify-content:center;align-items:center;margin:auto;padding:3rem;max-width:1200px}

.cms_hand__typing__3yYma{font-family:'Architects Daughter', cursive}.cms_content__heading__ClY0W{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}

.upload-content_hand__typing__2gfxO{font-family:'Architects Daughter', cursive}.upload-content_content__heading__ZOuTX{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.upload-content_UploadContent__3OrR6{display:flex;flex-direction:column;justify-content:center;align-items:center;width:calc(max(50vw, 400px));margin:auto}.upload-content_UploadContent__3OrR6 .upload-content_tags__aya8o{margin:10px 5px}.upload-content_UploadContent__3OrR6 .ant-input{border-radius:15px !important;padding:10px 15px !important;margin:10px !important}.upload-content_UploadContent__3OrR6 .ant-tag{margin:5px}

.comic_hand__typing__3dBix,.comic_Comic__25Ig7 .comic_date__1XTWE{font-family:'Architects Daughter', cursive}.comic_content__heading__1-ocX{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}.comic_Comic__25Ig7{padding:10px;display:flex;flex-direction:column;text-align:left}.comic_Comic__25Ig7 .comic_max__38ihh,.comic_Comic__25Ig7 .comic_image__container__2vSc0,.comic_Comic__25Ig7 .comic_image__container__2vSc0>img,.comic_Comic__25Ig7 .comic_footer__2xLhm,.comic_Comic__25Ig7 .comic_description__3ghK0{max-width:calc(min(100vw - 50px, 600px));margin:auto}.comic_Comic__25Ig7 h1,.comic_Comic__25Ig7 h4{color:#121212}.comic_Comic__25Ig7 h4{font-size:18px;letter-spacing:1px;word-spacing:3px}.comic_Comic__25Ig7 .comic_image__container__2vSc0>img{align-self:center;margin:20px}.comic_Comic__25Ig7 .comic_footer__2xLhm{display:flex;justify-content:space-between;align-items:center}.comic_Comic__25Ig7 .comic_description__3ghK0{padding:20px 0px}

.comic-slug_hand__typing__a5wa8{font-family:'Architects Daughter', cursive}.comic-slug_content__heading__CpXmo{color:rgba(0,0,0,0.85)}body{overflow-x:hidden}h1,h3{margin:.75rem}h3{font-weight:400;letter-spacing:.2rem;word-spacing:.3rem}

.App_App__15LM-{text-align:center;display:flex;flex-direction:column;min-height:100vh}.ant-btn-primary{color:rgba(0,0,0,0.85)}.ant-btn-primary:hover{color:rgba(0,0,0,0.5)}

