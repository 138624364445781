@import "../../styles/variables.scss";

.Button {
  border: none;
  padding: 0 30px;
  background: none;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.Body {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -110px;

  .Navigation {
    button {
      @extend .hand__typing;
      @extend .Button;
    }

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 0px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 3rem;
    max-width: 1200px;
  }
}
