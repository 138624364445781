.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

:global {
  .ant-btn-primary {
    color: rgba(0,0,0,.85);
  }
  .ant-btn-primary:hover {
    color: rgba(0,0,0,.5);
  }
}
