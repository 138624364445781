.wide {
  width: calc(100vw + 10px);
}

.Header {
  margin: auto;
  background-image: url("/static/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  a {
    font-size: 15px;
    letter-spacing: 1.5px;
    text-decoration: underline;
  }

  .logo {
    margin: 0.75rem;
    height: 5em;
  }

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
    z-index: 2;
  }
}

.shape {
  @extend .wide;
  transform: rotate(-1.75deg);
  position: relative;
  left: -10px;
  top: -3rem;
  background-color: #fecc33;
  padding: 4rem;
  z-index: -1;
}
