@import '../../styles/variables.scss';

.UploadContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(max(50vw, 400px));
    margin: auto;

    .tags {
        margin: 10px 5px;
    }

    :global {
        .ant-input {
            border-radius: 15px !important;
            padding: 10px 15px !important;
            margin: 10px !important;
        }
        .ant-tag {
            margin: 5px;
        }
    }
}