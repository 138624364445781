@import "../../styles/variables.scss";

.LoadingComic {
  display: flex;
  flex-direction: column;
  text-align: left;
  .image__container {
    margin: 20px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
