@import '../../styles/variables.scss';

.Book {
    max-width: 800px;

    h1 {
        color: $darkgray;
    }

    button {
        margin: 2rem;
    }

    .book__cover {
        height: 350px;
        margin: 2rem;
    }

    .desc {
        text-align: left;
    }
}