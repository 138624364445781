@import '../../styles/variables.scss';

.About {
    max-width: 800px;

    h1 {
        color: $darkgray;
    }

    .desc {
        text-align: left;
        margin-top: 2rem;
    }

    .headshot {
        margin: auto;
        width: 250px;
        height: 250px;
        background-size: 275px;
        background-image: url('JasonLeeHodges.jpg');
        background-position: 60% 25%;
        background-repeat: no-repeat;
        border-radius: 1.2rem;
    }
}