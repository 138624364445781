@import "../../styles/variables.scss";

.Comic {
  .max {
    max-width: calc(min(100vw - 50px, 600px));
    margin: auto;
  }

  h1,
  h4 {
    color: $darkgray;
  }
  h4 {
    font-size: 18px;
    letter-spacing: 1px;
    word-spacing: 3px;
  }
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  .image__container {
    @extend .max;
    > img {
      align-self: center;
      @extend .max;
      margin: 20px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .max;
  }

  .date {
    @extend .hand__typing;
  }
  .description {
    @extend .max;
    padding: 20px 0px;
  }
}
