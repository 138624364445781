$black: #000;
$yellow: #fecc33;
$small: 2rem;
$darkgray: #121212;
$lightgray: #e3e3e3;

.hand__typing {
    @import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
    font-family: 'Architects Daughter', cursive;
}

.content__heading {
    color: rgba(0,0,0,0.85);
}

:global {
    body {
        overflow-x: hidden;
        // background-color: $darkgray;
    }

    h1, h3 {
        margin: .75rem;
    }
    h3 {
        font-weight: 400;
        letter-spacing: .2rem;
        word-spacing: .3rem;
    }
}